import { PhoneIcon } from '@chakra-ui/icons';
import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const phone_number = '+998881202227'

const PhoneIconBox = () => {
    const [scrolled, setScrolled] = useState(false);
    // Adjust the size and positioning on different breakpoints
    const size = useBreakpointValue({ base: '60px', md: '80px' });
    const bottom = useBreakpointValue({ base: '70px', md: '70px' });
    const right = useBreakpointValue({ base: '40px', md: '50px' });

    const handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 100) {
            // If the scroll is more than 10px
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Box position="fixed" bottom={bottom} right={right} zIndex="tooltip" display={scrolled ? "block" : "none"}>
            <IconButton
                as={RouterLink}
                to={`tel:${phone_number}`}
                aria-label="Call us"
                icon={<PhoneIcon />}
                border="2px solid #369bb9"
                size="lg"
                fontSize="2xl"
                isRound={true}
                colorScheme="teal"
                boxShadow="lg"
                width={size}
                height={size}
                bg="transparent"
                color="primary.500"

                _hover={{
                    background: "primary.500",
                    color: "white"
                }}
            />
        </Box>
    );
};

export default PhoneIconBox;
