// src/theme.js
import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: {
    50: "#ebf5f8",
    100: "#d7ebf1",
    200: "#afd7e3",
    300: "#86c3d5",
    400: "#5eafc7",
    500: "#369bb9",
    600: "#2b7c94",
    700: "#205d6f",
    800: "#163e4a",
    900: "#0b1f25",
  },
  secondary: {
    50: "#fafafa", // lightest grey
    100: "#f5f5f5",
    200: "#eeeeee",
    300: "#e0e0e0",
    400: "#bdbdbd",
    500: "#9e9e9e", // default grey
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121", // darkest grey
  },
  accent: "#f9a825", // vibrant yellow for accents
  background: "#f0f2f5", // soft neutral for the background
  neutral: {
    50: "#fdfdfd", // off-white
    100: "#f7f7f7", // very light gray
    200: "#eeeeee", // light gray
    300: "#e0e0e0", // light-medium gray
    400: "#bdbdbd", // medium gray
    500: "#9e9e9e", // true medium gray
    600: "#757575", // medium-dark gray
    700: "#616161", // dark gray
    800: "#424242", // darker gray
    900: "#212121", // almost black gray
  },
  text: "#212121",
  success: {
    // For success messages or indicators
    500: "#4CAF50",
  },
  error: {
    // For error messages or indicators
    500: "#F44336",
  },
  warning: {
    // For warning messages or indicators
    500: "#FFEB3B",
  },
  info: {
    // For informational messages or indicators
    500: "#2196F3",
  },
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme(
  {
    config,
  },
  { colors },
  { config },
  {
    components: {
      Button: {
        baseStyle: ({ colorMode }) => ({
          fontWeight: "bold", // Ensuring text is bold across all button states
          textTransform: "uppercase", // Capitalizing button text for emphasis
          transition: "all 0.5s ease", // Smooth transition for background and transform
          _hover: {
            transform: "translateY(-2px)", // Slight lift effect on hover
            boxShadow: "lg", // Adding a larger shadow on hover for depth
          },
          _active: {
            transform: "translateY(1px)", // Slight press-in effect on active
          },
        }),
        variants: {
          custom: ({ colorMode }) => ({
            transition: "all 0.5s ease", // Smooth transition for background and transform
            borderRadius: "full", // Unique asymmetric border radius
            bgGradient: "linear(to-r, primary.300, primary.700)",
            color: "white", // Adjusting text color based on color mode
            border: "2px solid white", // Adding border
            _hover: {
              bgGradient: colorMode === "dark" ? "linear(to-l, primary.500, primary.700)" : "linear(to-l, primary.500, primary.700)",
              color: "white",
            },
          }),
        },
        defaultProps: {
          variant: "custom",
        },
      },
    },
  }
);

export default theme;
