import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Link as ChakraLink,
  Collapse,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Icon,
  IconButton,
  Image,
  LinkBox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { Link as ScrollLink } from "react-scroll";

import logo from "../assets/images/logo.png";

import { motion } from "framer-motion";

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionImage = motion(Image);

const NAV_ITEMS = [
  { label: "Главная", href: "header" },
  // { label: "О нас", href: "/about" },
  {
    label: "Услуги",
    href: "services",
  },
  { label: "Преимущества", href: "advantages" },
  {
    label: "Виды услуг",
    href: "service-types",
  },
  { label: "Документы", href: "documents" },
  { label: "Отзывы", href: "testimonials" },
  { label: "FAQ", href: "faq" },
  { label: "Контакты", href: "contacts" },
];

export default function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      // If the scroll is more than 10px
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <MotionBox
      variants={variants}
      as="nav"
      bg="white"
      transition="top .5s ease-out"
      position={scrolled ? "fixed" : "relative"}
      top={scrolled ? "0" : "-50%"}
      align="center"
      w="full"
      justify={{ base: "space-between", md: "space-around" }}
      css={{
        backdropFilter: "blur(10px)",
      }}
      zIndex="1000"
    >
      <Container maxW="container.xl">
        <Flex
          color="text"
          px={{ base: 4 }}
          justify={{ base: "space-between", lg: "" }}
          align={"center"}
          h="100%"
          overflowY="hidden"
          flexWrap="wrap"
        >
          <Flex align={"center"} py={2}>
            <ChakraLink
              as={ScrollLink}
              to="header"
              minW="75px"
              smooth={true}
              duration={500}
            >
              <MotionImage
                variants={variants}
                transition={{ duration: 0.5, delay: 0.4 }}
                src={logo}
                h={14}
                alt="Company logo"
              />
            </ChakraLink>
          </Flex>
          <Flex display={{ base: "none", lg: "flex" }} ml={10} gap={0}>
            <DesktopNav />
          </Flex>
          {/* <Flex display={{ base: "none", lg: "flex" }}>
            <ScrollAnimation>
              <Button
                size="lg"
                p={8}
                as={RouterLink}
                to={`tel:${phone_number}`}
              >
                Связаться
              </Button>
            </ScrollAnimation>
          </Flex> */}
          <Flex display={{ base: "flex", lg: "none" }} w="min-content">
            <IconButton
              color="white"
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={5} h={5} />
                ) : (
                  <HamburgerIcon w={8} h={8} />
                )
              }
              bg="primary.500"
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
              outline="none"
              position="relative"
              zIndex="8888"
              _hover={{
                bg: "primary.700",
                color: "white",
              }}
            />
          </Flex>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <MobileNav onClose={onClose} isOpen={isOpen} onToggle={onToggle} />
        </Collapse>
      </Container>
    </MotionBox>
  );
}

const DesktopNav = () => {
  // const hideNavbar = useNavbarContext();
  const linkColor = "black";
  const [activeNav, setActiveNav] = useState(null);

  const handleMouseEnter = (label) => {
    setActiveNav(label);
  };

  const handleMouseLeave = () => {
    setActiveNav(null);
  };

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Stack direction={"row"} gap={0} align="center" h="3rem">
      {NAV_ITEMS.map((navItem) => (
        <MotionBox
          variants={variants}
          key={navItem.label}
          h="100%"
          onMouseLeave={handleMouseLeave}
          onMouseEnter={() => handleMouseEnter(navItem.label)}
        >
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <ChakraLink
                as={ScrollLink}
                smooth={true}
                duration={500}
                display="flex"
                justifyContent="center"
                alignItems="center"
                px={4}
                h="100%"
                my={0}
                to={navItem.href ?? "#"}
                fontSize={"md"}
                fontWeight={500}
                textTransform="uppercase"
                w="100%"
                color={activeNav === navItem.label ? "white" : linkColor}
                rounded="full"
                _hover={{
                  textDecoration: "none",
                  bgGradient: "linear(to-r, primary.500, primary.700)",
                }}
              >
                {navItem.label}
              </ChakraLink>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent border={0} boxShadow={"xl"} bg="white" w="100%">
                <Stack gap={0}>
                  {navItem.children.map((child) => (
                    <DesktopSubNav
                      key={child.label}
                      {...child}
                      onMenuLeave={handleMouseLeave}
                    />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </MotionBox>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, children, color, onMenuLeave }) => {
  // const hideNavbar = useNavbarContext();
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [activeNav, setActiveNav] = useState(null);

  const handleMouseEnter = (label) => {
    setActiveNav(label);
    setShowSubMenu(true);
  };

  const handleMouseLeave = () => {
    setActiveNav(null);
    setShowSubMenu(false);
  };

  return (
    <Flex
      align="flex-start"
      textAlign="start"
      direction="column"
      onMouseEnter={() => handleMouseEnter(label)}
      onMouseLeave={handleMouseLeave}
      m={0}
      p={0}
    >
      <LinkBox
        as={ScrollLink}
        to={href}
        // onClick={() => hideNavbar()}
        px={5}
        minW="250px"
        py={3}
        textTransform="uppercase"
        w="100%"
        fontSize={"md"}
        fontWeight={500}
        bg={activeNav === label ? "primary.500" : "transparent"}
        color={activeNav === label ? "white" : "inherit"}
        _hover={{
          textDecoration: "none",
        }}
      >
        {label}
      </LinkBox>
      {showSubMenu && children && (
        <MotionBox
          position="absolute"
          left="100%"
          top="0"
          bg="primary.500"
          boxShadow="md"
          w="350px"
          minW="300px"
          maxW="500px"
          color="white"
        >
          {/* Recursive rendering or similar logic for displaying nested links */}
          {children.map((child) => (
            <DesktopSubNav key={child.label} {...child} color="white" />
          ))}
        </MotionBox>
      )}
    </Flex>
  );
};

const MobileNav = ({ onClose, isOpen, onToggle }) => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      zIndex="300"
      position="relative"
    >
      <MotionBox position="absolute" top="6" right="8" zIndex="9999">
        <IconButton
          as={CloseIcon}
          color="primary.600"
          onClick={onToggle}
          w={5}
          h={5}
          bg="transparent"
          variant={"ghost"}
          cursor="pointer"
          aria-label={"Toggle Navigation"}
        />
      </MotionBox>
      <DrawerOverlay />
      <DrawerContent pb={4}>
        <DrawerHeader borderBottomWidth="1px" h="100%">
          Меню
        </DrawerHeader>
        <DrawerBody
          sx={{
            "&::-webkit-scrollbar": { width: "8px", height: "8px" },
            "&::-webkit-scrollbar-track": { background: "#f0f0f0" },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#bdbdbd",
              borderRadius: "4px",
              border: "2px solid #f0f0f0",
            },
            scrollbarWidth: "thin",
            scrollbarColor: "#bdbdbd #f0f0f0",
          }}
        >
          <Stack p={4}>
            {NAV_ITEMS.map((navItem) => (
              <MobileNavItem
                key={navItem.label}
                {...navItem}
                onClose={onClose}
              />
            ))}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

const MobileNavItem = ({ label, href, children, onClose }) => {
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack
      spacing={2}
      // onClick={children ? onToggle : undefined}
      onClick={(e) => e.stopPropagation()}
    >
      <Flex py={2} justify={"space-between"} align={"center"}>
        <ChakraLink
          as={ScrollLink}
          to={href || "#"}
          smooth={true}
          duration={500}
          onClick={onClose} // Close the drawer when a nav item is clicked
          _hover={{ textDecoration: "none" }}
        >
          <MotionText
            variants={variants}
            transition={{ duration: 0.5, delay: 0.2 }}
            fontWeight={600}
          >
            {label}
          </MotionText>
        </ChakraLink>
        {children && (
          <Icon
            color="primary.900"
            rounded="full"
            as={ChevronDownIcon}
            transform={isOpen ? "rotate(180deg)" : ""}
            transition="transform 0.2s"
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
          />
        )}
      </Flex>
      {isOpen && (
        <Stack pl={4}>
          {children.map((child) => (
            <MobileNavItem key={child.label} {...child} onClose={onClose} /> // Recursive call for nested items
          ))}
        </Stack>
      )}
    </Stack>
  );
};
