import {
  Box,
  Link as ChakraLink,
  Flex,
  Image,
  Text
} from "@chakra-ui/react";
import React from "react";

import { Link as RouterLink } from "react-router-dom";

import logo from "../assets/images/logo.png";

import { motion } from "framer-motion";
import ScrollAnimation from "./ScrollAnimation";

const MotionBox = motion(Box);
const MotionText = motion(Text);
const MotionImage = motion(Image);

const Footer = () => {
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
  return (
    <MotionBox as="footer">
      {/* <MotionBox bg="primary.900" color="white" paddingY="6">
        <Container maxW="container.xl" py={12}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={4}>
            <MotionBox>
              <MotionHeading
                variants={variants}
                py={4}
                color="white"
                fontSize="2xl"
              >
                Компания DezCorp
              </MotionHeading>
              <ScrollAnimation>
                <MotionText
                  variants={variants}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  color="gray.300"
                  fontSize="lgmd"
                  pt={5}
                >
                  Компания{" "}
                  <RouterLink
                    to="/"
                    color="primary.500"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    DezCorp
                  </RouterLink>{" "}
                  предлагает услуги по уничтожению насекомых и грызунов в
                  Ташкенте. Наша цель
                </MotionText>
              </ScrollAnimation>
              <ScrollAnimation>
                <MotionText
                  variants={variants}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  color="gray.300"
                  fontSize="lgmd"
                  py={5}
                >
                  Компания DezCorp © 2024. Все права защищены.
                </MotionText>
              </ScrollAnimation>
            </MotionBox>

            <MotionBox>
              <MotionHeading
                variants={variants}
                py={4}
                color="white"
                fontSize="2xl"
                pb={10}
              >
                Полезные ссылки
              </MotionHeading>
              <ScrollAnimation>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/about"
                    color="gray.300"
                    textTransform="uppercase"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    О нас
                  </ChakraLink>
                </MotionBox>
              </ScrollAnimation>
              <ScrollAnimation>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/services"
                    color="gray.300"
                    textTransform="uppercase"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Услуги
                  </ChakraLink>
                </MotionBox>
              </ScrollAnimation>
              <ScrollAnimation>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/advantages"
                    color="gray.300"
                    textTransform="uppercase"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Преимущества
                  </ChakraLink>
                </MotionBox>
              </ScrollAnimation>
              <ScrollAnimation>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/blog"
                    color="gray.300"
                    textTransform="uppercase"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Блог
                  </ChakraLink>
                </MotionBox>
              </ScrollAnimation>
              <ScrollAnimation>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/contact"
                    color="gray.300"
                    textTransform="uppercase"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Контакты
                  </ChakraLink>
                </MotionBox>
              </ScrollAnimation>
              <ScrollAnimation>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/testimonials"
                    color="gray.300"
                    textTransform="uppercase"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Отзывы
                  </ChakraLink>
                </MotionBox>
              </ScrollAnimation>
            </MotionBox>
            <ScrollAnimation>
              <MotionBox>
                <MotionHeading
                  variants={variants}
                  py={4}
                  color="white"
                  fontSize="2xl"
                  pb={10}
                >
                  Из наших блогов
                </MotionHeading>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/blog"
                    color="gray.300"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Как Проходит Уничтожение Тараканов Холодным Туманом?
                  </ChakraLink>
                </MotionBox>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/blog"
                    color="gray.300"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Поможет ли Ремонт Избавиться от Тараканов
                  </ChakraLink>
                </MotionBox>
                <MotionBox py={2}>
                  <ChakraLink
                    as={RouterLink}
                    to="/blog"
                    color="gray.300"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    Подготовка помещения к дезинсекции
                  </ChakraLink>
                </MotionBox>
              </MotionBox>
            </ScrollAnimation>
            <MotionBox>
              <MotionHeading
                variants={variants}
                py={4}
                color="white"
                fontSize="2xl"
                pb={10}
              >
                Контакты
              </MotionHeading>
              <MotionBox py={2}>
                <ScrollAnimation>
                  <MotionText
                    variants={variants}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    color="gray.300"
                  >
                    Наш офис: г. Ташкент, Яшнабадский район, ул. Джаркурганская,
                    20
                  </MotionText>
                </ScrollAnimation>
              </MotionBox>
              <MotionBox py={3}>
                <ChakraLink
                  as={RouterLink}
                  my={2}
                  w="fit-content"
                  to={`tel:${phone_number}`}
                  display="flex"
                  alignItems="center"
                  _hover={{
                    textDecoratio: "none",
                  }}
                >
                  <PhoneIcon w={4} h={4} mr="2" color="accent" />
                  <ScrollAnimation>
                    <MotionText
                      variants={variants}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      color="primary.500"
                    >
                      {phone_number}
                    </MotionText>
                  </ScrollAnimation>
                </ChakraLink>
                <ChakraLink
                  w="fit-content"
                  my={2}
                  as={RouterLink}
                  to={telegram_link}
                  target="_blank"
                  display="flex"
                  alignItems="center"
                  _hover={{
                    textDecoratio: "none",
                  }}
                >
                  <Icon as={FaTelegram} w={5} h={5} mr={2} color="accent" />
                  <ScrollAnimation>
                    <MotionText
                      variants={variants}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      color="primary.500"
                    >
                      Написать в телеграм
                    </MotionText>
                  </ScrollAnimation>
                </ChakraLink>
              </MotionBox>
            </MotionBox>
          </SimpleGrid>
        </Container>
      </MotionBox> */}
      <MotionBox bg="primary.800" py={3}>
        <Flex
          align="center"
          direction={{ base: "column", md: "row" }}
          justify="space-around"
          mx={4}
        >
          <ChakraLink
            as={RouterLink}
            to="/"
            py={2}
            _hover={{
              textDecoration: "none",
            }}
          >
            <Flex align="center" justify="space-between">
              <MotionImage
                variants={variants}
                transition={{ duration: 0.5, delay: 0.4 }}
                w="4rem"
                src={logo}
              />
              <ScrollAnimation>
                <MotionText
                  variants={variants}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  color="white"
                >
                  DezCorp.uz
                </MotionText>
              </ScrollAnimation>
            </Flex>
          </ChakraLink>
          <ScrollAnimation>
            <MotionText
              variants={variants}
              transition={{ duration: 0.5, delay: 0.2 }}
              color="white"
              py={2}
            >
              2024. DezCorp © Все права защищены.
            </MotionText>
          </ScrollAnimation>
        </Flex>
      </MotionBox>
    </MotionBox>
  );
};

export default Footer;
