import { Box, Spinner } from "@chakra-ui/react";
import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PhoneIconBox from "./components/PhoneIconBox";
import ScrollToTop from "./components/ScrollToTop";

// const ContactInfo = lazy(() => import("./components/ContactHeader"));
// const ServiceTypes = lazy(() => import("./pages//Services/ServiceTypes"));
// const About = lazy(() => import("./pages/About"));
// const Advantages = lazy(() => import("./pages/Advantages"));
// const Contact = lazy(() => import("./pages/Contact"))
const Home = lazy(() => import("./pages/Home"));
// const Apartments = lazy(() => import("./pages/Services/Apartments"));
// const Cafe = lazy(() => import("./pages/Services/Cafe"));
// const Covid = lazy(() => import("./pages/Services/Covid"));
// const Fog = lazy(() => import("./pages/Services/Fog"));
// const House = lazy(() => import("./pages/Services/House"));
// const Organizations = lazy(() => import("./pages/Services/Organizations"));
// const Services = lazy(() => import("./pages/Services/Services"));

// const Ants = lazy(() => import("./pages/Services/Ants"));
// const Bedbugs = lazy(() => import("./pages/Services/Bedbugs"));
// const Cockroach = lazy(() => import("./pages/Services/Cockroach"));
// const Deratization = lazy(() => import("./pages/Services/Deratization"));
// const Disinfection = lazy(() => import("./pages/Services/Disinfection"));
// const Disinsection = lazy(() => import("./pages/Services/Disinsection"));
// const Fleas = lazy(() => import("./pages/Services/Fleas"));
// const Flies = lazy(() => import("./pages/Services/Flies"));
// const Mosquitoes = lazy(() => import("./pages/Services/Mosquitoes"));
// const Rodents = lazy(() => import("./pages/Services/Rodents"));
// const Scorpions = lazy(() => import("./pages/Services/Scorpions"));

// const Blog = lazy(() => import("./pages/Blog/Blog"));
// const BlogDetail = lazy(() => import("./pages/Blog/BlogDetail"));
// const TestimonialsPage = lazy(() => import("./pages/Testimonials"));
const NotFoundPage = lazy(() => import("./components/Page404"));

const usePageTracking = () => {
  useEffect(() => {
    const handleRouteChange = () => {
      window.dataLayer.push({
        event: "pageview",
        page: window.location.pathname + window.location.search,
      });
    };

    // Trigger the event for the initial page load
    handleRouteChange();

    // Add the event listener for subsequent route changes
    window.addEventListener("locationchange", handleRouteChange);

    return () => {
      window.removeEventListener("locationchange", handleRouteChange);
    };
  }, []);
};

function App() {
  usePageTracking();
  return (
    <BrowserRouter>
      {/* <Flex direction="column" minH="100vh" justifyContent={"space-between"}> */}
      <ScrollToTop />
      {/* <ContactInfo /> */}
      <Navbar />
      <PhoneIconBox />
      <Suspense
        fallback={
          <Box minH="100vh" textAlign="center" pt="20">
            <Spinner size="xl" />
          </Box>
        }
      >
        {" "}
        {/* Adjust styling as needed */}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/about" element={<About />} /> */}
          {/* <Route path="/contact" element={<Contact />} /> */}
          {/* <Route path="/advantages" element={<Advantages />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blog_id" element={<BlogDetail />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />

          <Route path="/services" element={<Services />} />
          <Route path="/service-types" element={<ServiceTypes />} />
          <Route path="/services/apartments" element={<Apartments />} />
          <Route path="/services/organizations" element={<Organizations />} />
          <Route path="/services/houses" element={<House />} />
          <Route path="/services/cafe-disinfection" element={<Cafe />} />
          <Route path="/services/covid19-disinfection" element={<Covid />} />
          <Route path="/services/cold-fog-disinfection" element={<Fog />} />

          <Route path="/disinfection" element={<Disinfection />} />
          <Route path="/disinsection" element={<Disinsection />} />
          <Route path="/deratization" element={<Deratization />} />
          <Route path="/pest-control/bedbugs" element={<Bedbugs />} />
          <Route path="/pest-control/cockroaches" element={<Cockroach />} />
          <Route path="/pest-control/scorpions" element={<Scorpions />} />
          <Route path="/pest-control/flies" element={<Flies />} />
          <Route path="/pest-control/mosquitoes" element={<Mosquitoes />} />
          <Route path="/pest-control/ants" element={<Ants />} />
          <Route path="/pest-control/rodents" element={<Rodents />} />
          <Route path="/pest-control/fleas" element={<Fleas />} /> */}

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      {/* </Flex> */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
